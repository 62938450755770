export enum UrlInternal {
  HOME = "/",
  LOGIN = "/login",
  INTRODUCE = "/introduce",
  DESCRIBE = "/describe",
  FORM_PARTICIPATION = "/form-participation",
  REGISTER_FORM = "/register-form",
  REGISTER_ACCEPT = "/register-accept",
  REGISTER_ACCEPT_READ = "/register-read",
}
